export default [
  {
    title: "序号",
    dataIndex: "index",
    fixed: "left",
    width: 60,
    customRender: (value, item, index) => index + 1,
  },
  {
    title: "产品编号",
    dataIndex: "number",
    width: 180,
    ellipsis: true,
  },
  {
    title: "产品名称",
    dataIndex: "name",
    width: 240,
    ellipsis: true,
  },
  {
    title: "产品条码",
    dataIndex: "barcode",
    width: 180,
    ellipsis: true,
  },
  // {
  //   title: "客户",
  //   dataIndex: "client_name",
  //   width: 120,
  //   ellipsis: true,
  // },
  {
    title: "计费单位",
    dataIndex: "charge_unit_display",
    width: 100,
    ellipsis: true,
  },
  {
    title: "入库配送单价(元)",
    dataIndex: "stock_in_transport_unit_price",
    width: 130,
    ellipsis: true,
  },
  {
    title: "入库卸货单价(元)",
    dataIndex: "stock_in_handling_unit_price",
    width: 130,
    ellipsis: true,
  },
  {
    title: "出库配送单价(元)",
    dataIndex: "stock_out_transport_unit_price",
    width: 130,
    ellipsis: true,
  },
  {
    title: "出库装车单价(元)",
    dataIndex: "stock_out_handling_unit_price",
    width: 130,
    ellipsis: true,
  },
  {
    title: "仓储单价(元)",
    dataIndex: "storage_unit_price",
    width: 100,
    ellipsis: true,
  },
  {
    title: "操作",
    dataIndex: "action",
    scopedSlots: { customRender: "action" },
    fixed: "right",
    width: 100,
  },
];
